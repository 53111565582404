import getJavaDemoCode from './java.js'
import getGoDemoCode from './go.js'
import getPyDemoCode from './python.js'
import getPhpDemoCode from './php.js'
import getNetDemoCode from './net.js'

export default {
  data() {
    return {

    }
  },
  methods: {
    // 实时生成代码示例数据
    generateCodeData() {
      let paramJson = null;
      
      if(this.requestType === "form") {
        const data = this.extractTableValues(this.paramsTableData)
        paramJson = '"' + JSON.stringify(data).replace(/"/g, '\\"') + '"'
      } else {
        paramJson = JSON.stringify(this.requestParamJson)
      }
      this.extractFormValues(paramJson);
      console.log('paramJson', paramJson)
    },

    // 转换请求参数 表单编辑的示例值为JSON
    extractTableValues(params) {
      const result = [];

      const dp = (param) => {
        // forEach 比 for更具可读性。。。
          if ((param.type === 'Obj' || param.type === 'Map') && param.children && param.children.length > 0) {
              const obj = {};
              param.children.forEach(child => {
                  const value = dp(child);
                  if (value !== undefined && value !== null && value !== "") {
                      obj[child.name] = value;
                  }
              });
              return Object.keys(obj).length > 0 ? obj : null;
          } else if ((param.type === 'List' || param.type === 'Set') && param.children && param.children.length > 0) {
              const arr = [];
              param.children.forEach(child => {
                  const value = dp(child);
                  if (value !== undefined && value !== null && value !== "") {
                      arr.push(value);
                  }
              });
              return arr.length > 0 ? arr : null;
          } else if (param.type === 'Single') {
              return param.value !== "" ? param.value : null;
          }
      };

      params.forEach(param => {
          const value = dp(param);
          if (value !== undefined && value !== null && value !== "") {
              result.push(value);
          } else {
              // 保持最外层的数据类型
              if (param.type === 'Obj' || param.type === 'Map') {
                  result.push({});
              } else if (param.type === 'List' || param.type === 'Set') {
                  result.push([]);
              } else {
                  result.push(null);
              }
          }
      });
      return result;
    },

    extractFormValues(paramJson) {
      
      const {
        appKey, 
        appSecret, 
        accessToken,
        algorithm,
        baseUri,
      } = this.getDefaultParams()
      const method = "POST";
      const apiUri = this.basicFormData.invocationPath;
      const unitCode = this.unitCode;
      const useJos = this.basicFormData.appSource === 2 ? true : false;
      const languages = this.languageHashMap;
      const data = {
        baseUri,
        appKey,
        appSecret,
        accessToken,
        algorithm,
        method,
        apiUri,
        unitCode,
        useJos,
        paramJson,
        languages,
        headers: {
          "content-type": this.basicFormData.contentType,
        },
        lopTz: this.$t('codeGen.lopTz'),
        userAgent: this.$t('codeGen.userAgent')
      };

      const javaCode = getJavaDemoCode(data)
      const pythonCode = getPyDemoCode(data)
      const goCode = getGoDemoCode(data)
      const phpCode = getPhpDemoCode(data)
      const dotnet_standard_2_0Code = getNetDemoCode(data)
      const codeList = [
        {
          name: 'java8',
          code: javaCode
        },
        {
          name: 'python3',
          code: pythonCode
        },
        {
          name: 'go',
          code: goCode
        },
        {
          name: 'php',
          code: phpCode
        },
        {
          name: 'dotnet_standard_2_0',
          code: dotnet_standard_2_0Code
        },
      ] 
      this.codeDemoList = codeList
      this.codeDemoList.forEach((item) => {
        if (item.name === this.languageHashMap[this.tabIdx]) {
          this.codeDemo = item.code;
        }
      })
    },
    getDefaultParams() {
      const { 
        appSource, 
        appType, 
      } = this.basicFormData;
      let {
        appKey, 
        appSecret, 
        accessToken,
        algorithm,
      } = this.basicFormData;
      appKey = appKey || this.$t('commonDevelopmentTools.apiTestTool.5pcombvu4lg0')
      appSecret = appSecret || this.$t('commonDevelopmentTools.apiTestTool.5pcombvu4lg0')
      
      // accessToken 需要填写的情况
      if(
        ( appSource === 1 && appType !== 4 ) ||
        ( appSource === 2 )
      ) {
        accessToken = accessToken || this.$t('commonDevelopmentTools.apiTestTool.5pcombvu4q80')
      }

      // algorithm 需要填写的情况
      if(appSource === 1 &&
      (appType === 4 || appType === 2)) {
        algorithm = algorithm || this.$t('commonDevelopmentTools.apiTestTool.5pcombvu53k0')
      }

      let baseUri = null // 不是写错 后端字段就这样
      if(this.runtimeEnv === "prod") {
        baseUri = 'https://api.jdl.com'
      } else if(this.runtimeEnv === "uat") {
        baseUri = 'https://uat-api.jdl.com'
      }
      // 如果是预发环境则代码中 生产对于 uat 预发对于 test 仅用作研发自测使用
      if(location.hostname.includes('uat')) {
        if(this.runtimeEnv === "prod") {
          baseUri = 'https://uat-api.jdl.com'
        } else if(this.runtimeEnv === "uat") {
          baseUri = 'https://test-api.jdl.com'
        }
      }
      // 国际站存在环境为空的情况 且 国际站仅有语法环境 直接写死为预发环境
      if(this.site === 'us') {
        baseUri = 'https://uat-intl-api.jdl.com'
      } else if(this.site === 'eu') {
        baseUri = ' https://uat-intl-holland-api.jingdonglogistics.com'
      }
      return {
        appKey, 
        appSecret, 
        accessToken,
        algorithm,
        baseUri,
      }
    },
    
  },  
  mounted() {
    
  },
  watch: {
    basicFormData: {
      handler(n) {
        this.generateCodeData()
      },
      inmmediate: true,
      deep: true
    },
    paramsTableData: {
      handler(n) {
        this.generateCodeData()
      },
      deep: true
    },
    requestParamJson: {
      handler(n) {
        this.generateCodeData()
      },
      deep: true
    },
    requestType: {
      handler(n) {
        this.generateCodeData()
      },
    },
    runtimeEnv: {
      handler(n) {
        this.generateCodeData()
      },
    },
  },
}