import bizapinterface from "@/api/extranet/extranetLcp/bizapinterface";
import bizunit from "@/api/extranet/extranetLcp/bizunit";
import apiTest from "@/api/extranet/extranetLcp/apiTest";
import app from "./app";
import oauth from "./oauth";

// 外网平台统一注册API
const extranetLcp = { bizapinterface, bizunit, apiTest, app, oauth };

export default extranetLcp;
