import request_msg from "@/utils/request_msg";

const app: any = {
  /**
   * 根据appKey查询应用名称
   *
   * @param {Object} params 请求参数
   * @param {string} params.appKey - 应用编码
   *
   * @returns {Promise} 表示根据appKey查询应用名称返回的Promise对象
   */
  getAppByAppKey: (params: any) =>
    request_msg.get("/app/getAppByAppKey", { params }),
};

export default app;
