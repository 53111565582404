const getDemoCode = (data) => {
  const {
    baseUri,
    appKey,
    appSecret,
    accessToken,
    apiUri,
    algorithm,
    paramJson,
    unitCode,
    useJos,
    lopTz,
    userAgent
  } = data
  const str = 
`import base64
import hashlib
import hmac
import time
import urllib.request
from datetime import datetime
from urllib.parse import urlencode
def sign(algorithm: str, data: bytes, secret: bytes) -> str:
    if algorithm == "md5-salt":
        h = hashlib.md5()
        h.update(data)
        return h.digest().hex()
    elif algorithm == "HMacMD5":
        return base64.b64encode(hmac.new(secret, data, hashlib.md5).digest()).decode("UTF-8")
    elif algorithm == "HMacSHA1":
        return base64.b64encode(hmac.new(secret, data, hashlib.sha1).digest()).decode("UTF-8")
    elif algorithm == "HMacSHA256":
        return base64.b64encode(hmac.new(secret, data, hashlib.sha256).digest()).decode("UTF-8")
    elif algorithm == "HMacSHA512":
        return base64.b64encode(hmac.new(secret, data, hashlib.sha512).digest()).decode("UTF-8")
    raise NotImplemented("Algorithm " + algorithm + " not supported yet")
if __name__ == "__main__":
    opener = urllib.request.build_opener()
    base_uri = "${baseUri}"
    app_key = "${appKey}"
    app_secret = "${appSecret}"
    ${accessToken ? `access_token = "${accessToken}"` : ""}
    domain = "${unitCode}"
    path = "${apiUri}"
    method = "POST"
    algorithm = "${algorithm}"
    body = ${paramJson}
    timestamp = datetime.now().strftime("%Y-%m-%d %H:%M:%S")
    content = "".join([
        app_secret,
        ${accessToken ? `"access_token", access_token,` : ""}
        "app_key", app_key,
        "method", path,
        "param_json", body,
        "timestamp", timestamp,
        "v", "2.0",
        app_secret
    ])
    sign_ = sign(algorithm, content.encode("UTF-8"), app_secret.encode("UTF-8"))
    uri = base_uri + path
    queries = {
        "LOP-DN": domain,
        "app_key": app_key,
        ${accessToken ? `"access_token": access_token,` : ""}
        "timestamp": timestamp,
        "v": "2.0",
        "sign": sign_,
        "algorithm": algorithm
    }
    offset = str(int(-time.timezone / 3600))
    headers = {
        # ${lopTz}
        "lop-tz": offset,
        # ${userAgent}
        "User-Agent": "lop-http/python3",
        ${useJos ? `"X-UseJosAuth": "true"` : ''}
        "content-type": "application/json;charset=utf-8",
    }
    url = uri + "?" + urlencode(queries)
    http_request = urllib.request.Request(url=url, data=body.encode("UTF-8"), headers=headers)
    http_response = opener.open(http_request)
    print(http_response.status)
    print(http_response.headers)
    print(http_response.read().decode("UTF-8"))
`;
return str
}

export default getDemoCode