import request_msg from "@/utils/request_msg";

const oauth: any = {
  /**
   * 根据appKey授权oauth
   *
   * @param {Object} params 请求参数
   * @param {string} params.clientId - 应用编码 APPKey
   *
   * @returns {Promise} 表示根据appKey授权oauth返回的Promise对象
   */
  authorize: (params: any, headers: any) =>
    request_msg.get("/oauth/authorize", { params, headers }),
};

export default oauth;
