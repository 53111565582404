const getDemoCode = (data) => {
  const {
    baseUri,
    appKey,
    appSecret,
    accessToken,
    apiUri,
    algorithm,
    paramJson,
    unitCode,
    useJos,
    lopTz,
    userAgent
  } = data
  const str = 
`using System;
using System.Collections.Generic;
using System.Net;
using System.Net.Http;
using System.Security.Cryptography;
using System.Text;
using System.Threading.Tasks;
namespace example
{
    class MainClass
    {
        public static async Task Main(string[] args)
        {
            var client = new HttpClient();
            var baseUri = "${baseUri}";
            var appKey = "${appKey}";
            var appSecret = "${appSecret}";
            ${accessToken ? `var accessToken = "${accessToken}";` : ''}
            var domain = "${unitCode}";
            var path = "${apiUri}";
            var method = "POST";
            var algorithm = "${algorithm}";
            var body = ${paramJson};
            var timestamp = DateTime.Now.ToString("yyyy-MM-dd HH:mm:ss");
            var content = string.Join("", new string[] {
                appSecret,
                ${accessToken ? `"access_token", accessToken,` : ''}
                "app_key",appKey,
                "method", path,
                "param_json", body,
                "timestamp", timestamp,
                "v","2.0",
                appSecret
            });
            var sign = Sign(algorithm, Encoding.UTF8.GetBytes(content), Encoding.UTF8.GetBytes(appSecret));
            var uri = baseUri + path;
            var httpRequest = new HttpRequestMessage();
            httpRequest.Method = new HttpMethod(method);
            httpRequest.Content = new ByteArrayContent(Encoding.UTF8.GetBytes(body));
            var uriBuilder = new UriBuilder(uri);
            var query = new Dictionary<string, string>();
            query["LOP-DN"] = domain;
            ${accessToken ? `query["access_token"] = accessToken;`: ''}
            query["app_key"] = appKey;
            query["timestamp"] = timestamp;
            query["v"] = "2.0";
            query["sign"] = sign;
            query["algorithm"] = algorithm;
            uriBuilder.Query = httpBuildQuery(query);
            httpRequest.RequestUri = new Uri(uriBuilder.ToString());
            var offset = DateTimeOffset.Now.Offset.Hours;
            // ${lopTz}
            httpRequest.Headers.TryAddWithoutValidation("lop-tz", offset.ToString());
            // ${userAgent}
            httpRequest.Headers.TryAddWithoutValidation("User-Agent", "lop-http/dotnet");
            ${useJos ? `httpRequest.Headers.TryAddWithoutValidation("X-UseJosAuth", "true");`: ''}
            httpRequest.Headers.TryAddWithoutValidation("content-type", "application/json;charset=utf-8");
            var httpResponse = await client.SendAsync(httpRequest);
            Console.WriteLine(httpResponse.StatusCode);
            Console.WriteLine(httpResponse.Headers);
            Console.WriteLine(Encoding.UTF8.GetString(await httpResponse.Content.ReadAsByteArrayAsync()));
        }
        public static string Sign(string algorithm, byte[] data, byte[] secret)
        {
            if (algorithm == "md5-salt")
            {
                return BitConverter.ToString(MD5.Create().ComputeHash(data)).Replace("-", "").ToLower();
            }
            else if (algorithm == "HMacMD5")
            {
                return Convert.ToBase64String(new HMACMD5(secret).ComputeHash(data));
            }
            else if (algorithm == "HMacSHA1")
            {
                return Convert.ToBase64String(new HMACSHA1(secret).ComputeHash(data));
            }
            else if (algorithm == "HMacSHA256")
            {
                return Convert.ToBase64String(new HMACSHA256(secret).ComputeHash(data));
            }
            else if (algorithm == "HMacSHA512")
            {
                return Convert.ToBase64String(new HMACSHA512(secret).ComputeHash(data));
            }
            throw new NotSupportedException("Algorithm " + algorithm + " not supported yet");
        }
        public static String httpBuildQuery(System.Collections.Generic.Dictionary<String, String> query)
        {
            var stringBuilder = new StringBuilder();
            var first = true;
            foreach (KeyValuePair<string, string> pair in query)
            {
                if (!first)
                {
                    stringBuilder.Append("&");
                }
                else
                {
                    first = false;
                }
                stringBuilder.Append(pair.Key).Append("=").Append(WebUtility.UrlEncode(pair.Value));
            }
            return stringBuilder.ToString();
        }
    }
}
`;
return str
}
export default getDemoCode
