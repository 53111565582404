<template>
  <div class="token-search">
    <div class="tit">{{ $t('tokenQuery.index.useGuide') }}</div>
    <ul class="txt-ul">
      <li>{{ $t('tokenQuery.index.step1') }}</li>
      <li>{{ $t('tokenQuery.index.step2') }}</li>
      <li>{{ $t('tokenQuery.index.step3') }}<a :href="isInternational ? (site === 'us' ? 'http://open.cloud.jdl.com/us/#/devSupport/163158' : 'http://open.cloud.jdl.com/eu/#/devSupport/163021') : 'https://open.jdl.com/#/devSupport/53392'" target="_blank">{{ $t('tokenQuery.index.authDesc') }}</a>。</li>
    </ul>
    <el-divider class="help-divider" />
    <div class="tit">{{ $t('tokenQuery.index.queryConditions') }}</div>
    <el-row>
      <el-col :span="12" class="split-pane-left">
        <el-form label-width="120px" label-position="left" :model="form" :rules="formRules" ref="inputUrlForm">
          <el-form-item label="access token" prop="token">
            <el-input v-model="form.token" :placeholder="$t('tokenQuery.index.queryPlaceholder')" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchToken">{{ $t('tokenQuery.index.search') }}</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-divider class="help-divider" />
    <div class="tit">{{ $t('tokenQuery.index.queryResults') }}</div>
    <el-alert :title="$t('tokenQuery.index.errorResult')" type="info" show-icon :closable="false" v-if="tokenSearchTips"
      class="token-search-tips">
    </el-alert>

    <el-table :data="tableData" style="width: 90%">
      <el-table-column prop="env" :label="$t('tokenQuery.index.environment')" width="220">
      </el-table-column>
      <el-table-column prop="appKey" label="appkey" min-width="300">
      </el-table-column>
      <el-table-column prop="pin" label="pin" min-width="200">
      </el-table-column>
      <el-table-column prop="authorizationTime" :label="$t('tokenQuery.index.authTime')"  width="160">
      </el-table-column>
      <el-table-column prop="expirationTime" :label="$t('tokenQuery.index.expirationTime')"  width="160">
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import request_msg from "@/utils/request_msg";
import isInternational from "@/utils/isInternational";
import { UserModule } from "@/store";
export default {
  name: "TokenSearch",
  data() {
    return {
      form: {
        token: ''
      },
      formRules: {
        token: [{ required: true, message: this.$t('tokenQuery.index.queryPlaceholder'), trigger: "blur" }],
      },
      site: UserModule.site,
      tableData: [],
      tokenSearchTips: false,
      isInternational: isInternational()
    }
  },
  methods: {
    searchToken() {
      this.$refs['inputUrlForm'].validate((valid) => {
        if (valid) {
          request_msg({
            url: "/oauth/getInfoByToken",
            method: "get",
            params: {
              accessToken: this.form.token,
            },
          })
            .then(async (res) => {
              const data = res.data;
              this.tableData = data;
              this.tokenSearchTips = this.tableData[0].appKey.indexOf("*") != -1;
            })
            .catch((error) => {
              this.tokenSearchTips = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.token-search {
  padding: 16px;
  min-width: 910px;
  background: #fff;

  .help-divider {
    margin: 20px 0;
  }

  .tit {
    font-size: 20px;
    color: #333;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .txt-ul {

    li {
      color: #666;
      font-size: 14px;
      line-height: 24px;

      a {
        color: #3c6ef0;
      }
    }
  }

  ::v-deep .token-search-tips {
    background-color: #f5f5f5;
    padding: 6px 16px;
    line-height: 20px;
    color: #999;
    margin-bottom: 16px;

    i {
      color: #999;
    }
  }

}
</style>