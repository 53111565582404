<template>
  <div class="common-development-tools">
    <ApiTestTool v-if="computeToolName === 'api-test'" />
    <Sign v-else-if="computeToolName === 'sign'" />
    <TokenSearch v-else-if="computeToolName === 'token-search'" />
  </div>
</template>

<script>
{/* <GlobalApiTestTool v-else-if="computeToolName === 'api-test' && isInternational" /> */}
import ApiTestTool from "@/views/extranet/openBusinessDocuments/docContent/commonDevelopmentTools/apiTestTool.vue";
import GlobalApiTestTool from "@/views/extranet/openBusinessDocuments/docContent/commonDevelopmentTools/globalApiTestTool.vue";
import Sign from "@/views/extranet/openBusinessDocuments/docContent/commonDevelopmentTools/sign/index.vue";
import TokenSearch from "@/views/extranet/openBusinessDocuments/docContent/commonDevelopmentTools/tokenSearch.vue";
import isInternational from '@/utils/isInternational'

export default {
  name: "CommonDevelopmentTools",
  components: {
    ApiTestTool,
    Sign,
    TokenSearch,
    GlobalApiTestTool
  },
  computed: {
    computeToolName() {
      return this.$route.params.toolName;
    },
    isInternational() {
      return isInternational()
    }
  },
};
</script>

<style lang="scss" scoped>
.common-development-tools {
  padding: 16px 12px 0;
  background-color: #f6f7fc;
}
</style>