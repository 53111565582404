<template>
  <div>
    <el-row>
      <el-col :span="12" class="split-pane-left">
        <el-form
          label-width="140px"
          label-position="left"
          :model="form"
          :rules="formRules"
          ref="inputUrlForm"
        >
          <el-form-item :label="$t('sign.InputUrlSignTool.5pipa29zly00')" prop="url">
            <el-input
              type="textarea"
              v-model="form.url"
              :placeholder="$t('sign.InputUrlSignTool.5pipa29zmo00')"
            />
          </el-form-item>
          <el-form-item prop="requestBody">
            <template slot="label">
              <span>
                {{ $t('sign.InputUrlSignTool.5pipa29zmtw0') }}
                <el-tooltip
                  :content="$t('sign.InputUrlSignTool.ensure')"
                  placement="top-start"
                >
                  <i class="el-icon-help-solid" />
                </el-tooltip>
              </span>
            </template>
            <el-input
              v-model="form.requestBody"
              :placeholder="$t('sign.InputUrlSignTool.5pipa29zmxk0')"
            />
          </el-form-item>
          <el-form-item label="app_secret" prop="appSecret">
            <el-input
              type="textarea"
              v-model="form.appSecret"
              :placeholder="$t('sign.InputUrlSignTool.5pipa29zn100')"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="showSteps">{{$t('sign.InputUrlSignTool.5pipa29zn580')}}</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12" class="split-pane-right">
        <sign-process
          v-if="signProcessModel.stepVisible"
          :access-token="signProcessModel.accessToken"
          :app-key="signProcessModel.appKey"
          :app-secret="signProcessModel.appSecret"
          :method="signProcessModel.method"
          :request-body="signProcessModel.requestBody"
          :timestamp="signProcessModel.timestamp"
          :version="signProcessModel.version"
          :base-url="signProcessModel.baseUrl"
          :steps-visible="signProcessModel.stepVisible"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SignProcess from './SignProcess';

export default {
  name: 'InputUrlSignTool',
  components: { SignProcess },
  data() {
    return {
      form: {
        url: '',
        requestBody: '',
        appSecret: '',
      },
      formRules: {
        url: [{ required: true, message: this.$t('sign.InputUrlSignTool.5pipa29zn8o0'), trigger: 'blur' }],
        requestBody: [{ required: true, message: this.$t('sign.InputUrlSignTool.5pipa29zndg0'), trigger: 'blur' }],
        appSecret: [{ required: true, message: this.$t('sign.InputUrlSignTool.5pipa29znh80'), trigger: 'blur' }],
      },
      signProcessModel: {
        baseUrl: '',
        domain: '',
        accessToken: '',
        appKey: '',
        appSecret: '',
        method: '',
        timestamp: '',
        version: '2.0',
        requestBody: '',
        stepVisible: false,
      },
    };
  },
  methods: {
    showSteps() {
      this.$refs['inputUrlForm'].validate((valid) => {
        if (valid) {
          const url = new URL(this.form.url);

          this.signProcessModel.baseUrl = url.origin;
          this.signProcessModel.domain = url.searchParams.get('LOP-DN');
          this.signProcessModel.accessToken =
            url.searchParams.get('access_token');
          this.signProcessModel.appKey = url.searchParams.get('app_key');
          this.signProcessModel.appSecret = this.form.appSecret;
          this.signProcessModel.method = url.pathname;
          this.signProcessModel.timestamp = url.searchParams.get('timestamp');
          this.signProcessModel.version = url.searchParams.get('v');
          this.signProcessModel.requestBody = this.form.requestBody;
          this.signProcessModel.stepVisible = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.split-pane-left {
  height: 100%;
  padding: 0 8px 0 0;
}

.split-pane-right {
  height: 100%;
  padding: 0 0 0 8px;
  border-left: solid 1px #ebeef5;
}
</style>
