<template>
  <div class="api-test">
    <div class="api-test__content">
      <div class="content__api-list">
        <h5 class="api-list__title">
          {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu2400") }}
        </h5>
        <el-divider class="api-list__divider" />
        <el-input
          v-model="interfaceKeyword"
          :clearable="true"
          :placeholder="$t('commonDevelopmentTools.apiTestTool.5pcombvu2401')"
          suffix-icon="el-icon-search"
          style="width: 100%"
          @change="handleGetBizUnitApiScrollPage"
        />
        <div
          :class="{
            'api-list__option-list': true,
            'is-empty': computeFilteredInterfaceList.length === 0,
          }"
        >
          <p
            v-if="computeFilteredInterfaceList.length === 0"
            class="option-list__empty-text"
          >
            {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu3m00") }}
          </p>
          <div
            v-for="(
              { id, apiUrl, apiName, apiNameSl }, index
            ) in computeFilteredInterfaceList"
            :key="id"
            class="option-list__item"
            ref="optionListItem"
            @click="onClickApiListOptionItem(index)"
          >
            <el-tooltip
              :disabled="judgeTooltipDisabled(index)"
              placement="right"
              popper-class="option-item-popper"
            >
              <template #content>
                <!-- <p>{{ apiUrl }}</p> -->
                <p>{{ formatApiNameText({ apiName, apiNameSl }) }}</p>
              </template>
              <div class="item__text-wrapper">
                <!--  v-show="false" 仅做tooltip展示计算用 -->
                <p v-show="false" class="text-wrapper__text">{{ apiUrl }}</p>
                <p class="text-wrapper__text">
                  {{ formatApiNameText({ apiName, apiNameSl }) }}
                </p>
                <p v-show="false" class="text-wrapper__hidden-text" ref="optionListItemApiUrl">
                  {{ apiUrl }}
                </p>
                <p
                  class="text-wrapper__hidden-text"
                  ref="optionListItemApiName"
                >
                  {{ formatApiNameText({ apiName, apiNameSl }) }}
                </p>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>

      <div class="content__api-info">
        <div class="api-info__tip">
          <div class="tip__title">
            <p class="title__name">
              {{
                formatInterfaceName(
                  basicFormData.interfaceName,
                  interfaceNameOptionList
                )
              }}
              {{
                basicFormData.interfaceName
                  | formatInterfaceUri(interfaceNameOptionList)
              }}
            </p>
            <router-link
              v-if="basicFormData.interfaceName"
              class="title__doc"
              target="_blank"
              :to="{
                name: 'ApiDocDetail',
                params: {
                  unitId: $route.params.unitId,
                  docId: basicFormData.interfaceName,
                },
              }"
              :clstag="clstag('', 'api_test_1657092725196|1')"
            >
              {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu3v40") }}
            </router-link>
          </div>
          <a
            class="tip__explanation"
            href="//open.jdl.com/#/devSupport/163065"
            target="_blank"
          >
            <i class="el-icon-help" style="margin-right: 4px" />
            <span>{{
              $t("commonDevelopmentTools.apiTestTool.5pcombvu3v41")
            }}</span>
          </a>
        </div>
        <div class="api-info__info-wrapper">
          <!-- 左侧内容区域 -->
          <div class="info-wrapper__left">
            <!-- 基础表单 -->
            <el-form
              class="left__basic-form"
              label-position="left"
              label-width="114px"
              ref="basicForm"
              :model="basicFormData"
            >
              <template v-for="item in basicFormList">
                <!-- 数据环境 -->
                <el-form-item
                  v-if="item.prop === 'runtimeEnv'"
                  :key="item.prop"
                  :label="item.label"
                  :prop="item.prop"
                >
                  <el-select v-model="runtimeEnv" style="width: 100%">
                    <el-option
                      v-for="item in runtimeOptionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <p v-if="env === 'uat' || env === 'prod'" class="left-tip">
                    {{ prodEnvHasSandboxTipHashMap[runtimeEnv] }}
                  </p>
                </el-form-item>
                <!-- 应用来源 -->
                <!-- <el-form-item
                  v-else-if="item.prop === 'appSource'"
                  :key="item.prop"
                  :label="item.label"
                  :prop="item.prop"
                >
                  <el-radio-group
                    v-model="basicFormData.appSource"
                    style="width: 100%"
                    @change="handleChangeAppSource"
                  >
                    <el-radio :label="1">{{$t('commonDevelopmentTools.apiTestTool.5pcombvu40g0')}}</el-radio>
                    <el-radio :label="2">{{$t('commonDevelopmentTools.apiTestTool.5pcombvu45s0')}}</el-radio>
                  </el-radio-group>
                </el-form-item> -->
                <!-- 应用类别 -->
                <el-form-item
                  v-else-if="
                    item.prop === 'appType' && basicFormData.appSource === 1
                  "
                  :key="item.prop"
                  :prop="item.prop"
                >
                  <template slot="label">
                    <p>
                      {{ item.label }}
                      <el-tooltip
                        :content="
                          $t('commonDevelopmentTools.apiTestTool.5pcombvu4b40')
                        "
                        placement="top-start"
                      >
                        <i class="el-icon-help-solid" />
                      </el-tooltip>
                    </p>
                  </template>
                  <el-radio-group
                    v-model="basicFormData.appType"
                    style="width: 100%"
                    @change="handleChangeAppType"
                  >
                    <el-radio :label="2"
                      >ISV/{{
                        $t("commonDevelopmentTools.apiTestTool.5pcombvu4g41")
                      }}</el-radio
                    >
                    <el-radio :label="4">{{
                      $t("commonDevelopmentTools.apiTestTool.5pcombvu4g40")
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- AppKey、AppSecret -->
                <el-form-item
                  v-else-if="['appKey', 'appSecret'].includes(item.prop)"
                  :key="item.prop"
                  :prop="item.prop"
                >
                  <template #label>
                    <span>{{ item.label }}</span>
                    <el-tooltip
                      :content="
                        $t('commonDevelopmentTools.apiTestTool.5pcombvu4lg0')
                      "
                      effect="dark"
                      placement="top"
                    >
                      <i class="el-icon-warning" style="margin-left: 8px" />
                    </el-tooltip>
                  </template>
                  <el-input
                    v-model.trim="basicFormData[item.prop]"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
                <!-- accessToken -->
                <el-form-item
                  v-else-if="
                    item.prop === 'accessToken' &&
                    basicFormData.appSource === 1 &&
                    basicFormData.appType === 2
                  "
                  :key="item.prop"
                  :prop="item.prop"
                >
                  <template slot="label">
                    <p>
                      {{ item.label }}
                      <a
                        href="//open.jdl.com/#/devSupport/163073"
                        target="_blank"
                        ><i class="el-icon-help-solid"
                      /></a>
                    </p>
                  </template>
                  <div class="token-wrapper">
                    <el-input
                      v-model.trim="basicFormData.accessToken"
                      :placeholder="
                        $t('commonDevelopmentTools.apiTestTool.5pcombvu4q80')
                      "
                      style="width: 100%"
                    ></el-input>
                    <div class="token-wrapper__tip">
                      <el-button type="text" @click="goToGetAccessToken">
                        {{
                          $t("commonDevelopmentTools.apiTestTool.5pcombvu4q81")
                        }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
                <!-- accessToken -->
                <el-form-item
                  v-else-if="
                    item.prop === 'accessToken' && basicFormData.appSource === 2
                  "
                  :key="item.prop"
                  :label="item.label"
                  :prop="item.prop"
                >
                  <div class="token-wrapper">
                    <el-input
                      v-model.trim="basicFormData.accessToken"
                      :placeholder="
                        $t('commonDevelopmentTools.apiTestTool.5pcombvu4q80')
                      "
                      style="width: 100%"
                    ></el-input>
                  </div>
                </el-form-item>
                <!-- 算法 -->
                <el-form-item
                  v-else-if="
                    item.prop === 'algorithm' &&
                    basicFormData.appSource === 1 &&
                    (basicFormData.appType === 4 || basicFormData.appType === 2)
                  "
                  :key="item.prop"
                  :label="item.label"
                  :prop="item.prop"
                >
                  <el-select
                    v-model="basicFormData.algorithm"
                    :placeholder="
                      $t('commonDevelopmentTools.apiTestTool.5pcombvu53k0')
                    "
                    style="width: 100%"
                  >
                    <el-option
                      v-for="algorithmItem in algorithmOptionList"
                      :key="algorithmItem"
                      :label="algorithmItem"
                      :value="algorithmItem"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-form>
            <el-divider />
            <div class="left__params-table">
              <p class="table__header">
                {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu5g80") }}
              </p>
              <el-radio-group v-model="requestType">
                <el-radio-button
                  label="form"
                  :clstag="clstag('', 'api_test_1657092725196|3')"
                  >{{
                    $t("commonDevelopmentTools.apiTestTool.5pcombvu5mo0")
                  }}</el-radio-button
                >
                <el-radio-button
                  label="json"
                  :clstag="clstag('', 'api_test_1657092725196|4')"
                >
                  {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu5mo1") }}
                </el-radio-button>
              </el-radio-group>
            </div>
            <!-- 表单编辑 -->
            <el-table
              v-if="requestType === 'form'"
              :data="paramsTableData"
              class="params-table"
              row-key="id"
              default-expand-all
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            >
              <el-table-column
                prop="name"
              >
              <!-- :label="$t('commonDevelopmentTools.apiTestTool.5pcombvu5u00')" -->
              <template slot="header">
                  <div class="header-size">
                    {{ $t('commonDevelopmentTools.apiTestTool.5pcombvu5u00') }}
                  </div>
                </template>
                <template slot-scope="scope">
                  <em v-if="scope.row.required === '1'" class="required">*</em
                  >{{ scope.row.name }}
                  <span style="color: #3c6ef0"
                    ><el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row.remark"
                      placement="top"
                      ><i class="el-icon-warning-outline"></i></el-tooltip
                  ></span>
                </template>
              </el-table-column>
              <el-table-column
                prop="objectType"
                :label="$t('commonDevelopmentTools.apiTestTool.5pcombvu60s0')"
                width="220px"
              >
                <template slot="header">
                  <div class="header-size">
                    {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu60s0") }}
                  <span
                    class="table__header-icon"
                    @click="showReplaceSample = true"
                  >
                    <i class="el-icon-edit"></i
                    ><span>{{
                      $t("commonDevelopmentTools.apiTestTool.5pcombvu6540")
                    }}</span>
                  </span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-input
                    v-if="scope.row.type == 'Single'"
                    :placeholder="scope.row.webShowType"
                    v-model.trim="scope.row.value"
                    size="small"
                  >
                    <template v-if="scope.row.canDelete === 0" slot="append">
                      <el-button
                        type="primary"
                        icon="el-icon-delete"
                        style="color: red"
                        @click="deleteListRow(scope.row.id)"
                      >
                        {{
                          $t("commonDevelopmentTools.apiTestTool.5pcombvu6541")
                        }}
                      </el-button>
                    </template>
                  </el-input>

                  <el-input
                    v-if="
                      (scope.row.type == 'Obj' &&
                        !scope.row.objectType.startsWith(
                          'java.util.ArrayList'
                        )) ||
                      scope.row.type == 'MapKeyValuePair'
                    "
                    :placeholder="scope.row.webShowType"
                    disabled
                    size="small"
                  >
                    <template v-if="scope.row.canDelete === 0" slot="append">
                      <el-button
                        type="primary"
                        icon="el-icon-delete"
                        style="color: red"
                        @click="deleteListRow(scope.row.id)"
                      >
                        {{
                          $t("commonDevelopmentTools.apiTestTool.5pcombvu6541")
                        }}
                      </el-button>
                    </template>
                  </el-input>

                  <el-input
                    v-if="
                      scope.row.type == 'List' ||
                      scope.row.type == 'Set' ||
                      scope.row.type == 'Array' ||
                      scope.row.objectType.startsWith('java.util.ArrayList')
                    "
                    :placeholder="scope.row.webShowType"
                    disabled
                    size="small"
                  >
                    <template slot="append">
                      <el-button
                        type="primary"
                        icon="el-icon-plus"
                        style="color: #409eff"
                        @click="addListRow(scope.row)"
                      >
                        {{
                          $t("commonDevelopmentTools.apiTestTool.5pcpvrev9m80")
                        }}
                      </el-button>
                    </template>
                  </el-input>

                  <el-input
                    v-if="scope.row.type == 'Map'"
                    :placeholder="scope.row.webShowType"
                    disabled
                    size="small"
                  >
                    <template slot="append">
                      <el-button
                        type="primary"
                        icon="el-icon-plus"
                        style="color: #409eff"
                        @click="addListRow(scope.row)"
                      >
                        {{
                          $t("commonDevelopmentTools.apiTestTool.5pcpvrev9m80")
                        }}
                      </el-button>
                    </template>
                  </el-input>
                </template>
              </el-table-column>
            </el-table>
            <!-- JSON编辑 -->
            <codemirror
              v-else
              v-model="requestParamJson"
              :options="requestParamOptions"
            />
            <div class="left__button-wrapper">
              <el-button
                :loading="submitTestLoading"
                type="primary"
                @click="handleSubmitTest"
              >
                {{
                  submitTestLoading
                    ? $t("commonDevelopmentTools.apiTestTool.5pcombvu68o0")
                    : $t("commonDevelopmentTools.apiTestTool.5pcombvu6co0")
                }}
              </el-button>
            </div>
          </div>
          <!-- 右侧内容区域 -->
          <div class="info-wrapper__right" v-loading="submitTestLoading">
            <el-tabs v-model="activeName">
              <el-tab-pane
                :label="$t('commonDevelopmentTools.apiTestTool.5pcombvu6gc0')"
                name="testResult"
              >
                <div
                  v-if="!requestJson && !responseJson"
                  class="code-demo__is-empty"
                >
                  <div class="is-empty__img"></div>
                  <p class="is-empty__title">
                    {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu6og0") }}
                  </p>
                  <p class="is-empty__desc">
                    {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu6sw0") }}
                  </p>
                </div>
                <div v-else>
                  <div class="right-box">
                    <h4 class="sub-title">
                      <span class="title-style"></span>
                      {{
                        $t("commonDevelopmentTools.apiTestTool.5pcombvu6y40")
                      }}
                    </h4>
                    <div>
                      <span>{{ testResult.url }}</span>
                      <i
                        v-if="testResult.url"
                        v-clipboard:copy="testResult.url"
                        class="el-icon-document-copy"
                      />
                    </div>
                  </div>
                  <div class="right-box">
                    <h4 class="sub-title" v-if="requestJson != null">
                      <span class="title-style"></span>
                      {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu5g80")
                      }}<span style="font-size: 12px; color: dodgerblue">
                        ({{
                          $t("commonDevelopmentTools.apiTestTool.5pcombvu5g81")
                        }})</span
                      >
                    </h4>
                    <h4 class="sub-title" v-else>
                      <span class="title-style"></span>
                      {{
                        $t("commonDevelopmentTools.apiTestTool.5pcombvu5g80")
                      }}
                    </h4>
                    <div v-if="requestJson != null" class="json-codemirror">
                      <codemirror
                        :value.sync="requestJson"
                        :options="cmOptions"
                      />
                    </div>
                    <div
                      v-else
                      style="
                        border: solid 1px;
                        border-color: #d9d9d9;
                        height: 230px;
                      "
                    ></div>
                  </div>
                  <div class="right-box">
                    <h4 class="sub-title" v-if="responseJson != null">
                      <span class="title-style"></span>
                      {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu71g0")
                      }}<span style="font-size: 12px; color: dodgerblue">
                        ({{
                          $t("commonDevelopmentTools.apiTestTool.5pcombvu5g80")
                        }})</span
                      >
                    </h4>
                    <h4 class="sub-title" v-else>
                      <span class="title-style"></span>
                      {{
                        $t("commonDevelopmentTools.apiTestTool.5pcombvu71g0")
                      }}
                    </h4>
                    <div v-if="responseJson != null" class="json-codemirror">
                      <codemirror
                        v-if="responseJson"
                        :value.sync="responseJson"
                        :options="cmOptions"
                      />
                    </div>
                    <div
                      v-else
                      style="
                        border: solid 1px;
                        border-color: #d9d9d9;
                        height: 230px;
                      "
                    ></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                :label="$t('commonDevelopmentTools.apiTestTool.5pcombvu75c0')"
                name="codeDemo"
              >
                <div
                  v-if="codeDemoList.length === 0"
                  class="code-demo__is-empty"
                >
                  <div class="is-empty__img"></div>
                  <p class="is-empty__title">
                    {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu77o0") }}
                  </p>
                  <p class="is-empty__desc">
                    {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu7a80") }}
                  </p>
                </div>
                <div v-else>
                  <TagTip class="tag-tip" :closable="false">
                    {{ $t("commonDevelopmentTools.apiTestTool.5pcombvu7d40") }}
                  </TagTip>
                  <el-row class="code-demo-button">
                    <div
                      v-for="(item, index) in codeList"
                      :key="index"
                      :class="{
                        right__button__active: tabIdx === index,
                        right__button: true,
                      }"
                      @click="handleClickButton(index)"
                    >
                      {{ item }}
                    </div>
                  </el-row>
                  <el-tooltip
                    v-if="codeDemo"
                    class="item"
                    effect="dark"
                    :content="
                      $t('commonDevelopmentTools.apiTestTool.5pcombvu7i00')
                    "
                    placement="top"
                  >
                    <i
                      v-if="codeDemo"
                      :clstag="clstag('', 'api_test_1657092725196|5')"
                      v-clipboard:copy="codeDemo"
                      class="el-icon-document-copy"
                    />
                  </el-tooltip>
                  <div class="code-demo-box">
                    <codemirror
                      v-if="codeDemo"
                      :value.sync="codeDemo"
                      :options="cmOptions"
                    />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('commonDevelopmentTools.apiTestTool.5pcombvu7l00')"
      :visible.sync="showReplaceSample"
      width="400px"
    >
      <div>{{ $t("commonDevelopmentTools.apiTestTool.5pcombvu7ng0") }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReplaceSample = false">{{
          $t("commonDevelopmentTools.apiTestTool.5pcombvu7q00")
        }}</el-button>
        <el-button type="primary" @click="handleConfirmReplaceSample">{{
          $t("commonDevelopmentTools.apiTestTool.5pcombvu7uw0")
        }}</el-button>
      </span>
    </el-dialog>
    <BlackFooter />
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import BlackFooter from '@/components/extranet/Footer/blackFooter.vue'
import extranetLcp from '@/api/extranet/extranetLcp/index'
import TagTip from '@/components/common/TagTip.vue'
import point from '@/mixins/point'
import { UserModule } from '@/store'
import 'codemirror/lib/codemirror.css'
// language
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/php/php.js'
import 'codemirror/mode/shell/shell.js'
// theme css
import 'codemirror/theme/ttcn.css'
// require active-line.js
import 'codemirror/addon/selection/active-line.js'
// closebrackets
import 'codemirror/addon/edit/closebrackets.js'
// foldGutter
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
import 'codemirror/addon/display/autorefresh.js'
import { mapState } from 'vuex'

export default {
  name: 'GlobalApiTestTool',
  components: {
    codemirror,
    BlackFooter,
    TagTip,
  },
  mixins: [point],
  data() {
    const self = this
    return {
      env: window.env.buildEnv,
      interfaceKeyword: '',
      requestParamOptions: {
        mode: {
          name: 'javascript',
          json: true,
        },
        scrollbarStyle: null,
        readOnly: false,
        autoCloseBrackets: true,
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        theme: 'ttcn',
        lineWrapping: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
      },
      cmOptions: {
        mode: {
          name: 'javascript',
          json: true,
        },
        autoRefresh: true,
        readOnly: true,
        autoCloseBrackets: true,
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        theme: 'ttcn',
        lineWrapping: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
      },
      // 生产环境API是否具有预发提示文案
      prodEnvHasSandboxTipHashMap: {
        prod: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8040'),
      },
      unitId: self.$route.params.unitId,
      docId: parseInt(self.$route.params.docId),
      basicFormData: {
        interfaceName: null,
        invocationPath: '',
        subordinateOpenBiz: '',
        appSource: 1,
        appType: 2,
        appKey: '',
        appSecret: '',
        accessToken: '',
        algorithm: null,
      },
      basicFormList: [
        {
          label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu83s0'),
          prop: 'interfaceName',
        },
        {
          label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu86k0'),
          prop: 'invocationPath',
        },
        {
          label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8ao0'),
          prop: 'subordinateOpenBiz',
        },
        {
          label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8dg0'),
          prop: 'appSource',
        },
        {
          label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8gg0'),
          prop: 'appType',
        },
        {
          label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8j00'),
          prop: 'runtimeEnv',
        },
        { label: 'AppKey', prop: 'appKey' },
        { label: 'AppSecret', prop: 'appSecret' },
        { label: 'AccessToken', prop: 'accessToken' },
        {
          label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8lg0'),
          prop: 'algorithm',
        },
      ],
      interfaceNameOptionList: [],
      methodId: null,
      // API类型 1:JSF 2:HTTP
      apiType: 1,
      qualifiedName: '',
      methodName: '',
      paramsTableData: [],
      algorithmOptionList: [
        'md5-salt',
        'HMacSHA1',
        'HMacMD5',
        'HMacSHA256',
        'HMacSHA512',
      ],
      requestType: 'form',
      from: 'web',
      testResult: {
        url: '',
        body: '',
      },
      idArray: [],
      maxId: 0,
      debugParamHasErr: false,
      // --加载状态指示器
      submitTestLoading: false,
      // --返回值
      responseJson: null,
      // --入参值
      requestJson: null,
      runtimeEnv: '',
      runtimeOptionList: [],
      requestParamJson: JSON.stringify(JSON.parse('[{}]'), null, ' '),
      activeName: 'testResult',
      codeList: ['Java', 'Python', 'Go', 'PHP', 'C#'],
      tabIdx: 0,
      unitCode: null,
      codeDemoList: [],
      languageHashMap: ['java8', 'python3', 'go', 'php', 'dotnet_standard_2_0'],
      codeDemo: null,
      showReplaceSample: false,
    }
  },
  filters: {
    formatInterfaceUri(interfaceId, interfaceNameOptionList) {
      const interfaceInfo = interfaceNameOptionList.find(
        ({ id }) => interfaceId === id
      )
      return (
        (interfaceInfo &&
          interfaceInfo.apiUrl &&
          `（${interfaceInfo.apiUrl}）`) ||
        ''
      )
    },
  },
  computed: {
    ...mapState({
      locale: state => state.user.locale,
    }),
    computeFilteredInterfaceList() {
      const { interfaceKeyword = '', interfaceNameOptionList = [] } = this
      return interfaceNameOptionList.filter(
        ({ apiUrl, apiName, apiNameSl }) => apiUrl.toLowerCase().indexOf(interfaceKeyword.toLowerCase()) > -1 ||
          apiName.toLowerCase().indexOf(interfaceKeyword.toLowerCase()) > -1 ||
          apiNameSl.toLowerCase().indexOf(interfaceKeyword.toLowerCase()) > -1
      )
    },
  },
  methods: {
    formatInterfaceName(interfaceId, interfaceNameOptionList) {
      const interfaceInfo = interfaceNameOptionList.find(
        ({ id }) => interfaceId === id
      )
      const { locale } = this
      if (locale === 'en-us') {
        return (interfaceInfo && interfaceInfo.apiNameSl) || ''
      }
      return (interfaceInfo && interfaceInfo.apiName) || ''
    },
    formatApiNameText(apiItem) {
      const { apiName = '', apiNameSl = '' } = apiItem
      const { locale } = this
      const hashMap = new Map().set('zh-cn', apiName).set('en-us', apiNameSl || apiName)
      return hashMap.get(locale) || ''
    },
    handleClickButton(index) {
      this.tabIdx = index
      this.codeDemoList.length > 0 &&
        this.codeDemoList.forEach((item) => {
          if (item.name === this.languageHashMap[index]) {
            this.codeDemo = item.code
          }
        })
    },
    // 查询业务单元下的api列表，支持滚动分页
    async getBizUnitApiScrollPage() {
      const unitId = parseInt(this.unitId)
      const params = { unitId, lastId: 0, size: 1000 }
      return await extranetLcp.bizapinterface.getBizUnitApiScrollPage(params)
    },
    // 根据API的ID查询API文档信息
    async getBizUnitApiDetails(id = 0) {
      const { interfaceName } = this.basicFormData
      id = id || interfaceName
      const params = { id }
      return await extranetLcp.bizapinterface.getBizUnitApiDetails(params)
    },
    // 获取API方法下所有参数可见映射
    async getBizApiMethodParamsVisibleMap() {
      const id = this.basicFormData.interfaceName
      const data = [id]
      return await extranetLcp.bizapinterface.getBizApiMethodParamsVisibleMap(
        data
      )
    },
    // 根据ID查询开放业务详情
    async getBizUnitById() {
      const id = parseInt(this.unitId)
      const params = { id }
      return await extranetLcp.bizunit.getBizUnitById(params)
    },
    async getMethodDetailsForApiTestUtil(params) {
      return await extranetLcp.bizapinterface.getMethodDetailsForApiTestUtil(
        params
      )
    },
    // 从网关获取格式化数据
    async getFormatDataForGateway() {
      const params = this.paramsTableData
      const data = { params }
      return await extranetLcp.apiTest.getFormatDataForGateway(data)
    },
    // 获取格式化json数据
    // async getFormatData(data) {
    //   return await extranetLcp.apiTest.getFormatData(data);
    // },
    // 测试工具发起测试
    async testUnitApi(data) {
      return await extranetLcp.bizapinterface.testUnitApi(data)
    },
    async codegen(data) {
      return await extranetLcp.bizapinterface.codegen(data)
    },

    judgeTooltipDisabled(index) {
      const optionListItemApiUrlRefList = this.$refs.optionListItemApiUrl
      const optionListItemApiNameRefList = this.$refs.optionListItemApiName
      if (
        optionListItemApiUrlRefList &&
        optionListItemApiNameRefList &&
        optionListItemApiUrlRefList[index] &&
        optionListItemApiNameRefList[index]
      ) {
        return optionListItemApiUrlRefList[index].clientWidth > 154
          ? false
          : !(optionListItemApiNameRefList[index].clientWidth > 154)
      }
      return true
    },
    onClickApiListOptionItem(index) {
      this.$refs.optionListItem.forEach((item) => {
        item.classList.remove('is-active')
      })
      this.$refs.optionListItem[index].classList.add('is-active')
      this.basicFormData.interfaceName =
        this.computeFilteredInterfaceList[index].id
      this.handleChangeInterfaceName(this.basicFormData.interfaceName)
      this.resetForm()
      // 清除测试结果及代码示例数据及JSON编辑数据
      this.requestJson = null
      this.responseJson = null
      this.codeDemoList = []
      this.codeDemo = null
      this.requestParamJson = JSON.stringify(JSON.parse('[{}]'), null, ' ')
    },
    // 加载接口名称选项列表，并选择第一项
    async handleGetBizUnitApiScrollPage() {
      const { data } = await this.getBizUnitApiScrollPage().catch(() => {
        throw new Error(
          'handleGetBizUnitApiScrollPage getBizUnitApiScrollPage'
        )
      })
      this.interfaceNameOptionList = data || []
      if (this.interfaceNameOptionList.length > 0) {
        this.$nextTick(() => {
          if (!isNaN(this.docId)) {
            let included = false
            this.interfaceNameOptionList.map((item, index) => {
              if (item.id === this.docId) {
                this.onClickApiListOptionItem(index)
                included = true
              }
            })
            if (!included) {
              this.onClickApiListOptionItem(0)
            }
          } else {
            this.onClickApiListOptionItem(0)
          }

        })
      }
    },
    // 加载接口信息数据
    async loadInterfaceInfoData(id = 0) {
      const { data } = await this.getBizUnitApiDetails(id).catch(() => {
        throw new Error('loadInterfaceInfoData getBizUnitApiDetails')
      })
      const { apiUrl, methodId, type, interfaceName, interfaceMethod } =
        data || {}
      this.basicFormData.invocationPath = apiUrl
      this.methodId = methodId
      this.apiType = type
      this.qualifiedName = interfaceName
      this.methodName = interfaceMethod
    },
    // 改变接口名称操作时
    async handleChangeInterfaceName(value) {
      await this.loadInterfaceInfoData(value).catch(() => {
        throw new Error('handleChangeInterfaceName loadInterfaceInfoData')
      })
      await this.getApiDebugParams().catch(() => {
        throw new Error('handleChangeInterfaceName getApiDebugParams')
      })
    },
    // 过滤不可见参数
    filterAndClearParamsValue(children, visibleMap, isReplace) {
      return children && children.length > 0
        ? children
          .filter(child => visibleMap[String(child.paramId)] !== 0)
          .map((child) => {
            const newObject = Object.assign({}, child)
            !isReplace && (newObject.value = '')
            newObject.children = this.filterAndClearParamsValue(
              newObject.children,
              visibleMap,
              isReplace
            )
            return newObject
          })
        : []
    },
    // 获取API方法下所有参数可见映射，过滤不可见参数
    async getApiDebugParams(isReplace) {
      const response = await this.getBizApiMethodParamsVisibleMap().catch(
        () => {
          throw new Error('getApiDebugParams getBizApiMethodParamsVisibleMap')
        }
      )
      const paramsVisibleMap = response.data || {}
      const params = { apiId: Number(this.basicFormData.interfaceName) }
      const { data } = await this.getMethodDetailsForApiTestUtil(params).catch(
        () => {
          throw new Error('getApiDebugParams getMethodDetailsForApiTestUtil')
        }
      )
      const paramsChildren = data || []
      this.paramsTableData = this.filterAndClearParamsValue(
        paramsChildren,
        paramsVisibleMap,
        isReplace
      )
      this.getMaxId(data)
    },

    // 遍历参数树形数据得到所有id值，取最大值
    getMaxId(data) {
      if (!data || !data.length) {
        return
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].id) {
          this.idArray.push(data[i].id)
        }
        if (data[i].children && data[i].children.length > 0) {
          this.getMaxId(data[i].children)
        }
      }
      this.maxId = Math.max(...this.idArray) + 1
    },

    // 加载开放业务信息数据
    async loadOpenBizInfoData() {
      const { data } = await this.getBizUnitById().catch(() => {
        throw new Error('loadOpenBizInfoData getBizUnitById')
      })
      const { hasUat, name, code } = data
      this.unitCode = code
      // hasUat为0则无预发环境，为1则有预发环境
      if (hasUat === 1) {
        this.runtimeEnv = 'prod'
        this.runtimeOptionList = [
          {
            value: 'prod',
            label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8o00'),
          },
          {
            value: 'uat',
            label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8r40'),
          },
        ]
      } else {
        this.runtimeEnv = 'prod'
        this.runtimeOptionList = [
          {
            value: 'prod',
            label: this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8o00'),
          },
        ]
      }
      this.basicFormData.subordinateOpenBiz = name
    },
    // 重置配置信息AppKey,AppSecret,AccessToken,algorithm表单数据
    resetForm() {
      this.basicFormData.appKey = ''
      this.basicFormData.appSecret = ''
      this.basicFormData.accessToken = ''
      this.basicFormData.algorithm =
        this.basicFormData.appSource === 2 ? null : 'md5-salt'
    },
    // 改变应用来源操作，重置表单数据
    handleChangeAppSource() {
      this.resetForm()
    },
    // 改变应用类别操作时，重置表单数据，变更算法选项list
    handleChangeAppType(value) {
      if (value === 4) {
        this.algorithmOptionList = [
          'md5-salt',
          'HMacSHA1',
          'HMacMD5',
          'HMacSHA256',
          'HMacSHA512',
          'sm3-salt',
        ]
      } else {
        this.algorithmOptionList = [
          'md5-salt',
          'HMacSHA1',
          'HMacMD5',
          'HMacSHA256',
          'HMacSHA512',
        ]
      }
      this.resetForm()
    },
    // 跳转页面获取accessToken
    goToGetAccessToken() {
      const { appKey } = this.basicFormData
      if (!appKey) {
        return this.$message.error(
          this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8ts0')
        )
      }
      // 跳转授权页，跳转地址根据界面上所选的【数据环境】，跳转至对应的授权页
      // const oauthUrl = this.$router.resolve({
      //   name: 'Oauth',
      //   params: { env: this.runtimeEnv, clientId: appKey },
      // })
      // // {域名}
      // window.open(oauthUrl.href, '_blank')
      const preffix = 'https://'
      const suffix = 'oauth.jdl.com'
      const url = `/oauth/authorize?client_id=${appKey}&redirect_uri=urn:ietf:wg:oauth:2.0:oob&response_type=code`
      const site = localStorage.getItem('site')

      let envUrl = ''
      let oauthUrl = ''
      let sitePrefix = ''

      if (site === 'eu') {
        sitePrefix = 'eu-'
      } else {
        sitePrefix = 'us-'
      }
      if (this.runtimeEnv === 'uat') {
        envUrl = 'uat-'
      }
      oauthUrl = preffix + sitePrefix + envUrl + suffix + url
      window.open(oauthUrl, '_blank')
    },
    // 提交测试操作
    async handleSubmitTest() {
      this.submitTestLoading = true
      const { appSource, appType, appKey, appSecret, accessToken, algorithm } =
        this.basicFormData
      if (!appKey) {
        this.submitTestLoading = false
        return this.$message.error(
          this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8ts1')
        )
      }
      if (!appSecret) {
        this.submitTestLoading = false
        return this.$message.error(
          this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8ts2')
        )
      }
      if (appSource === 1 && appType !== 4) {
        if (!accessToken) {
          this.submitTestLoading = false
          return this.$message.error(
            this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8ts3')
          )
        } if (appSource === 1 && appType === 4) {
          if (!algorithm) {
            this.submitTestLoading = false
            return this.$message.error(
              this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8ts4')
            )
          }
        }
      }
      if (appSource === 2 && !accessToken) {
        this.submitTestLoading = false
        return this.$message.error(
          this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8ts5')
        )
      }
      let paramJson = null
      // 先获取参数 再进行调用
      if (this.requestType === 'form') {
        const { data } = await this.getFormatDataForGateway().catch(() => {
          this.submitTestLoading = false
          // 清除测试结果及代码示例数据
          this.requestJson = null
          this.responseJson = null
          this.codeDemoList = []
          throw new Error('handleSubmitTest getFormatDataForGateway')
        })
        paramJson = data || ''
      } else {
        // const apiId = Number(this.basicFormData.interfaceName);
        const jsonString = this.requestParamJson
        // const params = { apiId, jsonString };
        // const { data } = await this.getFormatData(params).catch(() => {
        //   this.submitTestLoading = false;
        //   // 清除测试结果及代码示例数据
        //   this.requestJson = null;
        //   this.responseJson = null;
        //   this.codeDemoList = [];
        //   throw new Error("handleSubmitTest getFormatData");
        // });
        paramJson = jsonString || ''
        try {
          JSON.parse(paramJson)
        } catch (error) {
          this.$message.error(
            this.$t('commonDevelopmentTools.apiTestTool.5pcpvrev9m81')
          )
          this.submitTestLoading = false
          // 清除测试结果及代码示例数据
          this.requestJson = null
          this.responseJson = null
          this.codeDemoList = []
        }
      }
      const apiId = Number(this.basicFormData.interfaceName)
      const env = this.runtimeEnv
      const params = {
        apiId,
        appKey,
        appSecret,
        env,
        paramJson,
        appType: appSource,
        accessToken,
        algorithm,
      }
      this.doTest(params)
    },
    // 替换示例值
    async handleConfirmReplaceSample() {
      await this.getApiDebugParams('isReplace').catch((error) => {
        throw new Error('handleConfirmReplaceSample getApiDebugParams')
      })
      this.showReplaceSample = false
    },
    // 发起测试
    async doTest(params) {
      const paramJson = JSON.stringify(JSON.parse(params.paramJson), null, ' ')
      if (this.apiType === 2 && Array.isArray(paramJson)) {
        // HTTP 接口，直接为参数
        this.requestJson = paramJson[0]
      } else {
        // JSF接口,参数外层包含[]
        this.requestJson = paramJson
      }

      const { data } = await this.testUnitApi(params).catch(() => {
        this.submitTestLoading = false
        // 清除测试结果及代码示例数据
        this.responseJson = null
        this.codeDemoList = []
        throw new Error('doTest testUnitApi')
      })
      this.submitTestLoading = false
      if (data) {
        this.testResult = data
        if (this.testResult.body) {
          this.responseJson = JSON.stringify(
            JSON.parse(this.testResult.body),
            null,
            ' '
          )
        } else {
          this.responseJson = null
        }
        // 获取代码示例值
        this.queryCodeDemo(params)
      } else {
        this.$message.error(
          this.$t('commonDevelopmentTools.apiTestTool.5pcombvu8x40')
        )
      }
    },
    async queryCodeDemo(params) {
      const { appKey, appSecret, accessToken, algorithm } = this.basicFormData
      const method = 'POST'
      const apiUri = this.basicFormData.invocationPath
      const { unitCode } = this
      const useJos = this.basicFormData.appSource === 2
      const { paramJson } = params
      const languages = this.languageHashMap
      const data = {
        appKey,
        appSecret,
        accessToken,
        algorithm,
        method,
        apiUri,
        unitCode,
        useJos,
        paramJson,
        languages,
      }
      const response = await this.codegen(data).catch((error) => {
        // 清除代码示例数据
        this.codeDemoList = []
        throw new Error(`queryCodeDemo codegen ${error}`)
      })
      this.codeDemoList = response.data
      this.codeDemoList.length > 0 &&
        this.codeDemoList.forEach((item) => {
          if (item.name === this.languageHashMap[this.tabIdx]) {
            this.codeDemo = item.code
          }
        })
    },

    deleteListRow(id) {
      const temp_row = {}
      temp_row.children = this.paramsTableData
      temp_row.id = -100
      this.deleteRowDetail(temp_row, id)
    },
    deleteRowDetail(row, id) {
      if (row.children) {
        for (let i = 0; i < row.children.length; i++) {
          if (row.children[i].id === id) {
            row.children.splice(i, 1)
            return
          }
          this.deleteRowDetail(row.children[i], id)
        }
      }
    },
    addListRow(obj) {
      const needAdd = JSON.parse(JSON.stringify(obj.children[0]))
      this.changeSubId(needAdd)
      needAdd.canDelete = 0
      obj.children.push(needAdd)
    },
    changeSubId(obj) {
      if (obj.id) {
        obj.id = this.maxId
        this.maxId = this.maxId + 1
        if (obj.children) {
          for (const subObj of obj.children) {
            this.changeSubId(subObj)
          }
        }
      }
    },
    makeParamJson(paramJson, data) {
      const jsonIsArray = paramJson instanceof Array // 目标json是否为数组
      for (let i = 0, len = data.length; i < len; ++i) {
        const item = data[i]
        if (!item.children) {
          if (item.required === '1' && item.value === '') {
            this.$message.error(
              `${item.name}${this.$t(
                'commonDevelopmentTools.apiTestTool.5pcpvrev9m82'
              )}`
            )
            this.debugParamHasErr = true
            break
          }
          jsonIsArray
            ? paramJson.push(item.value)
            : (paramJson[item.name] = item.value)
        } else {
          // 有子节点
          const tempData = {}
          if (item.type == 'Map') {
            this.makeParamJsonOfMap(tempData, item.children)
          } else {
            const tempData =
              item.type == 'List' || item.type == 'Set' || item.type == 'Array'
                ? []
                : {}
            this.makeParamJson(tempData, item.children)
          }
          jsonIsArray
            ? paramJson.push(tempData)
            : (paramJson[item.name] = tempData)
        }
      }
    },
    makeParamJsonOfMap(paramJson, data) {
      console.log(JSON.stringify(data))
      for (let i = 0, len = data.length; i < len; ++i) {
        const item = data[i].children
        let key = ''
        if (item[0].type == 'Single') {
          key = item[0].value
        } else {
          const tempKeyData = {}
          this.makeParamJson(tempKeyData, item[0].children)
          key = tempKeyData
        }
        console.log(`key:${JSON.stringify(key)}`)
        let value = ''
        if (item[1].type == 'Single') {
          value = item[1].value
        } else {
          const tempValueData = {}
          this.makeParamJson(tempValueData, item[1].children)
          value = tempValueData
        }
        console.log(`value:${JSON.stringify(value)}`)
        paramJson[key] = value
      }
    },
  },
  async created() {
    this.loadOpenBizInfoData().catch(() => {
      throw new Error('created loadOpenBizInfoData')
    })
    await this.handleGetBizUnitApiScrollPage().catch(() => {
      throw new Error('created handleGetBizUnitApiScrollPage')
    })
    await this.loadInterfaceInfoData().catch(() => {
      throw new Error('created loadInterfaceInfoData')
    })
    await this.getApiDebugParams().catch(() => {
      throw new Error('created getApiDebugParams')
    })
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/debug-variables.scss";

.api-test {
  border-radius: 4px;

  .api-test__content {
    display: flex;
    justify-content: space-between;

    .content__api-list {
      margin-right: 12px;
      padding: 16px;
      width: 212px;
      border-radius: 4px 4px 0 0;
      background: #fff;

      .api-list__title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 600;
      }

      .api-list__divider {
        margin: 12px 0;
      }

      .api-list__option-list {
        position: relative;
        margin-top: 12px;
        margin-right: -16px;
        max-height: calc(100vh - 342px);
        min-height: calc(100vh - 260px);
        overflow: auto;

        &.is-empty {
          background-image: url("~@/assets/common/images/empty/empty.svg");
          background-size: 180px;
          background-repeat: no-repeat;
          background-position: 0 80px;
        }

        .option-list__empty-text {
          position: absolute;
          top: 200px;
          left: 50%;
          font-size: 16px;
          color: #707784;
          white-space: nowrap;
          transform: translateX(-55%);
        }

        .option-list__item {
          padding: 10px 12px;
          max-width: 180px;
          border: 1px solid #e4e9f0;
          border-top: 1px solid transparent;
          color: #666;
          cursor: pointer;

          &:nth-child(1) {
            border-top: 1px solid #e4e9f0;
            border-radius: 4px 4px 0 0;
          }

          &:nth-last-child(1) {
            border-radius: 0 0 4px 4px;
          }

          &:nth-child(2n) {
            background-color: #fafafa;
          }

          &:hover,
          &.is-active {
            color: #3c6ef0;
            background-color: rgba(60, 110, 240, 0.1);
            border: 1px solid #3c6ef0;
            border-radius: 4px;
          }

          .text-wrapper__text {
            width: 154px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .text-wrapper__hidden-text {
            position: absolute;
            z-index: -1;
            display: inline-block;
            overflow: hidden;
            max-width: 156px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .content__api-info {
      padding: 12px;
      padding-bottom: 0;
      flex-grow: 1;
      // flex布局右侧内容过长超出父元素容器设置width:0
      width: 0;
      background-color: #fff;
      border-radius: 4px;

      .api-info__tip {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        vertical-align: middle;

        .tip__title {
          display: flex;
          justify-content: flex-start;

          .title__name {
            display: inline-block;
            max-width: 400px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .title__doc {
            font-weight: normal;
            color: #3c6ef0;
            white-space: nowrap;
          }
        }

        .tip__explanation {
          color: #999;
        }
      }

      .api-info__info-wrapper {
        display: flex;
        background-color: #fff;
        border-top: 1px solid #e4e9f0;

        .info-wrapper__left {
          padding-top: 8px;
          padding-right: 8px;
          border-right: 1px solid #e4e9f0;
          // max-width: 448px;
          min-width: 448px;
          max-height: calc(100vh - 274px);
          min-height: calc(100vh - 180px);
          overflow: auto;
          background: #fff;
          flex: 2;

          .left__basic-form {
            .left-tip {
              color: red;
              font-size: 12px;
              line-height: 1;
              padding-top: 4px;
              position: absolute;
              top: 100%;
              left: 0;
            }
            .el-icon-help-solid {
              cursor: pointer;
            }
            .token-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              .token-wrapper__tip {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }
            }
          }
          .left__params-table {
            height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .table__header {
              font-weight: 500;
            }
            /deep/
              .el-radio-button__orig-radio:checked
              + .el-radio-button__inner {
              color: #3c6ef0;
            }
            /deep/ .el-radio-button__inner {
              width: 76px;
              height: 32px;
              padding: 8px 6px;
              background: #ffffff;
            }
          }
          .params-table {
            /deep/.el-table__header-wrapper {
              .cell {
                display: flex;
                justify-content: space-between;
              }
            }
            /deep/ .el-table__row {
              .cell {
                font-size: 12px;
                padding: 0 !important;
              }
              td {
                padding: 4px 0;
              }
              .el-input {
                input {
                  font-size: 12px;
                }
              }
            }
            .table__header-icon {
              color: #3c6ef0;
              // margin-left: 60px;
              cursor: pointer;
            }
            .el-icon-edit {
              margin-right: 6px;
              cursor: pointer;
            }
          }
          // 去掉表格的横线，但实际应使用form表单而不是table组件，待重构
          ::v-deep .el-table__row > td {
            border: none;
          }
          //去掉表格hover高亮当前行效果
          ::v-deep .el-table tbody tr:hover > td {
            background-color: #ffffff;
          }
          .el-table {
            ::v-deep tr:nth-child(7) {
              .mark {
                vertical-align: top;
              }
            }
          }

          .left__button-wrapper {
            position: sticky;
            bottom: 0;
            z-index: 7;
            margin: 0 -8px;
            padding: 12px;
            display: flex;
            justify-content: flex-end;
            box-shadow: 0 -6px 12px 0 rgba(0, 0, 0, 0.08);
            background: #fff;
          }
        }
        .info-wrapper__right {
          padding: 8px 0 0 16px;
          flex-grow: 1;
          flex: 3;
          max-height: calc(100vh - 274px);
          min-height: calc(100vh - 180px);
          overflow: auto;
          background: #fff;
          .tag-tip {
            min-width: 410px;
            /deep/.tagTip__tag {
              height: 40px;
            }
            /deep/.tagTip__content {
              line-height: 18px;
              white-space: normal;
            }
          }
          .code-demo-button {
            display: flex;
            .right__button {
              text-align: center;
              width: 62px;
              height: 28px;
              line-height: 28px;
              background: #ebf0fd;
              border-radius: 4px;
              margin: 0 5px;
              cursor: pointer;
            }
            .right__button__active {
              text-align: center;
              width: 62px;
              height: 28px;
              line-height: 28px;
              background: #3c6ef0;
              border-radius: 4px;
              margin: 0 5px;
              cursor: pointer;
              color: #fff;
            }
          }
          .el-icon-document-copy {
            position: absolute;
            right: 20px;
            top: 110px;
            margin: 8px;
            cursor: pointer;
            z-index: 3;
          }
          .code-demo-box {
            margin-top: 8px;
            height: calc(100vh - 342px);
            border: 1px solid #d9d9d9;
            overflow: auto;
            /deep/ .CodeMirror {
              height: auto;
            }
          }
          .code-demo__is-empty {
            .is-empty__img {
              height: 152px;
              width: 238px;
              margin: 120px auto 0;
              background-image: url("~@/assets/common/images/empty/empty.svg");
            }
            .is-empty__title {
              text-align: center;
              margin-bottom: 10px;
              font-size: 16px;
              color: #707784;
            }
            .is-empty__desc {
              text-align: center;
              font-size: 12px;
              color: #707784;
            }
          }

          .title-style {
            display: inline-block;
            width: 3px;
            height: 16px;
            background: rgba(60, 110, 240, 1);
            border-radius: 2px;
            margin-right: 9px;
            vertical-align: middle;
          }
          .right-box {
            .el-icon-document-copy {
              position: relative;
              right: 0;
              top: 0;
              margin-left: 8px;
              color: #3c6ef0;
              cursor: pointer;
            }
            .sub-title {
              font-weight: 600;
              margin-bottom: 12px;
            }
            margin-bottom: 20px;
            .title {
              font-size: 12px;
              margin-bottom: 5px;
            }
            .json-codemirror {
              border: solid 1px;
              border-color: #d9d9d9;
              /deep/ .CodeMirror {
                height: 240px;
              }
            }
            .textarea {
              border: 2px dotted #c2e9f5;
              font-size: 12px;
              line-height: 1.2;
            }
          }
        }
      }
    }
  }

  .black-footer {
    position: relative;
    margin: 0 -12px;

    /deep/ .black-footer-license-content {
      width: 890px;
    }
  }
}

.header-size {
  font-size: 12px;
}
/deep/ .vjs-tree {
  font-size: 14px;
  font-family: inherit;
}
</style>

<style lang="scss">
.option-item-popper {
  padding: 4px 16px;
}
</style>
