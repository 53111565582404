const getDemoCode = (data) => {
  const {
    baseUri,
    appKey,
    appSecret,
    accessToken,
    apiUri,
    algorithm,
    paramJson,
    unitCode,
    useJos,
    lopTz,
    userAgent
  } = data
  const str = 
`<?php
function sign($algorithm, $data, $secret)
{
    if ($algorithm == "md5-salt") {
        return md5($data);
    } else if ($algorithm == "HMacMD5") {
        return base64_encode(hash_hmac("md5", $data, $secret, true));
    } else if ($algorithm == "HMacSHA1") {
        return base64_encode(hash_hmac("sha1", $data, $secret, true));
    } else if ($algorithm == "HMacSHA256") {
        return base64_encode(hash_hmac("sha256", $data, $secret, true));
    } else if ($algorithm == "HMacSHA512") {
        return base64_encode(hash_hmac("sha512", $data, $secret, true));
    }
    throw new Exception("Algorithm " . $algorithm . " not supported yet");
}
$baseUri = "${baseUri}";
$appKey = "${appKey}";
$appSecret = "${appSecret}";
${accessToken ? `$accessToken = "${accessToken}";` : ""}
$domain = "${unitCode}";
$path = "${apiUri}";
$method = "POST";
$algorithm = "${algorithm}";
$body = ${paramJson};
$timestamp = date("Y-m-d H:i:s");
$content = implode("", array(
    $appSecret,
    ${accessToken ? `"access_token", $accessToken,` : ""}
    "app_key", $appKey,
    "method", $path,
    "param_json", $body,
    "timestamp", $timestamp,
    "v", "2.0",
    $appSecret
));
$sign = sign($algorithm, $content, $appSecret);
$query = http_build_query(array(
    "LOP-DN" => $domain,
    "app_key" => $appKey,
    ${accessToken ? `"access_token" => $accessToken,` : ""}
    "timestamp" => $timestamp,
    "v" => "2.0",
    "sign" => $sign,
    "algorithm" => $algorithm
));
$uri = $baseUri . $path . "?" . $query;
$offset = strval(date("Z") / 3600);
$headers = array(
    // ${lopTz}
    "lop-tz" => $offset,
    ${useJos ? `"X-UseJosAuth" => "true",` : ''}
    "content-type"=> "application/json;charset=utf-8",
);
$content = stream_context_create(array(
    "http" => array(
        "method" => $method,
        "header" => $headers,
        "content" => $body,
        // ${userAgent}
        "user_agent" => "lop-http/php",
    )
));
$response = @file_get_contents($uri, true, $content);
if ($response) {
    echo $response . PHP_EOL;
} else {
    echo error_get_last()["message"] . PHP_EOL;
}
?>
`;
return str
}

export default getDemoCode