const getDemoCode = (data) => {
    const {
    baseUri,
    appKey,
    appSecret,
    accessToken,
    apiUri,
    algorithm,
    paramJson,
    unitCode,
    useJos, // go 里面没找到
    lopTz,
    userAgent
  } = data
  const str = 
  `package main

  import (
    "bytes"
    "crypto/hmac"
    "crypto/md5"
    "crypto/sha1"
    "crypto/sha256"
    "crypto/sha512"
    "encoding/base64"
    "fmt"
    "io/ioutil"
    "net/http"
    "strings"
    "time"
  )

  func main() {
    client := http.Client{}
    baseUri := "${baseUri}"
    appKey := "${appKey}"
    appSecret := "${appSecret}"
    ${accessToken ? `accessToken := "${accessToken}"` : ''}
    domain := "${unitCode}"
    path := "${apiUri}"
    method := "POST"
    algorithm := "${algorithm}"
    body := ${paramJson}
    timestamp := time.Now().Format("2006-01-02 15:04:05")
    content := strings.Join([]string{
      appSecret,
      ${accessToken ? `"access_token", accessToken,` : ""}
      "app_key", appKey,
      "method", path,
      "param_json", body,
      "timestamp", timestamp,
      "v", "2.0",
      appSecret,
    }, "")
    sign, err := Sign(algorithm, []byte(content), appSecret)
    if err != nil {
      panic(err)
    }
    uri := baseUri + path
    httpRequest, err := http.NewRequest(method, uri, bytes.NewReader([]byte(body)))
    if err != nil {
      panic(err)
    }
    query := httpRequest.URL.Query()
      query.Add("LOP-DN", domain)
      ${accessToken ? `query.Add("access_token", accessToken)` : ''}
      query.Add("app_key", appKey)
      query.Add("timestamp", timestamp)
      query.Add("v", "2.0")
      query.Add("sign", sign)
      query.Add("algorithm", algorithm)
      httpRequest.URL.RawQuery = query.Encode()
      _, offset := time.Now().Zone()
      // ${lopTz}
      httpRequest.Header.Add("lop-tz", fmt.Sprintf("%d", offset/3600))
      // ${userAgent}
      httpRequest.Header.Add("User-Agent", "lop-http/go")
      httpRequest.Header.Add("content-type", "application/json;charset=utf-8")
    httpResponse, err := client.Do(httpRequest)
    if err != nil {
      panic(err)
    }
    b, err := ioutil.ReadAll(httpResponse.Body)
    if err != nil {
      panic(b)
    }
    println(httpResponse.Status)
    for k, v := range httpResponse.Header {
      fmt.Printf("%s: %s\", k, strings.Join(v, ", "))
    }
    println(string(b))
  }
    
  func Sign(algorithm string, data []byte, secret string) (string, error) {
    if algorithm == "md5-salt" {
      h := md5.New()
      if _, err := h.Write(data); err != nil {
        return "", err
      }
      return fmt.Sprintf("%x", h.Sum(nil)), nil
    } else if algorithm == "HMacMD5" {
      h := hmac.New(md5.New, []byte(secret))
      if _, err := h.Write(data); err != nil {
        return "", err
      }
      return base64.StdEncoding.EncodeToString(h.Sum(nil)), nil
    } else if algorithm == "HMacSHA1" {
      h := hmac.New(sha1.New, []byte(secret))
      if _, err := h.Write(data); err != nil {
        return "", err
      }
      return base64.StdEncoding.EncodeToString(h.Sum(nil)), nil
    } else if algorithm == "HMacSHA256" {
      h := hmac.New(sha256.New, []byte(secret))
      if _, err := h.Write(data); err != nil {
        return "", err
      }
      return base64.StdEncoding.EncodeToString(h.Sum(nil)), nil
    } else if algorithm == "HMacSHA512" {
      h := hmac.New(sha512.New, []byte(secret))
      if _, err := h.Write(data); err != nil {
        return "", err
      }
      return base64.StdEncoding.EncodeToString(h.Sum(nil)), nil
    }
    return "", fmt.Errorf("algorithm %s not supported yet", algorithm)
  }
  `;
  return str
}

export default getDemoCode